import React, { useState, useRef, useEffect } from "react";
import CurrencyInput from "react-currency-input-field";
import { Modal, ButtonGroup, Button, Label, Input, ButtonToggle, ModalHeader, ModalBody, ModalFooter, ListGroupItemHeading } from 'reactstrap'
import { useAuth0 } from "@auth0/auth0-react";
import PlacesAutocomplete from "react-google-places-autocomplete"
import { geocodeByPlaceId } from "react-google-places-autocomplete"
import Loading from "../Loading";
import 'react-smarttab/dist/index.css'
import '../Styles/Create_Location.css'
import 'react-input-range/lib/css/index.css'
import InputRange from "react-input-range";
import imagePlaceholder from "../../assets/Image_Placeholer.png"
import FacebookIcon from "../../assets/Facebook.png"
import InstagramIcon from "../../assets/Instagram.png"
import copy from "../../assets/copy.png"
import axios from "axios";
import ImageContainer from "./ImageContainer";
import { isUserAdmin, isUserAuthorized } from "../../userAuth"
import { BlockBlobClient } from "@azure/storage-blob";



const CreateLocationComponent = (props) => {
    //Variables
    const {
        user,
        getAccessTokenWithPopup,
        getAccessTokenSilently,
        isAuthenticated,
    } = useAuth0();


    const [locationID, setlocationID] = useState(props.location ? props.location._id : "");
    const inputFile = useRef()
    //const [innerLocation, setInnerLocation] = useState(props.edit ? {...props.location} : emtpyLocation)
    const [location, setLocation] = useState(props.edit ? {
        display_name: props.formattedAddress, lat: props.location.coordinates[1], lng: props.location.coordinates[0]
    } : {})
    const baseUrl = process.env.REACT_APP_SERVER
    const [accessToken, setAccessToken] = useState({})
    const [isAlertOpen, setIsAlertOpen] = useState(false)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)
    const [alert, setAlert] = useState({})



    //States
    const [categories, setCateogories] = useState([
        { key: "ACTIVITY", value: "Aktivität" },
        { key: "HEALTH", value: "Gesundheit" },
        { key: "SHOPPING", value: "Einkauf" },
        { key: "SUPPORT_CONSULTING", value: "Unterstützung und Beratung" },
        { key: "PLAYGROUND", value: "Spielplatz" },
        { key: "HIKING", value: "Wandern" },
        { key: "HOTEL", value: "Unterkunft" },
        { key: "EDU", value: "Bildung" },
        { key: "AQUA_FUN", value: "Wasserspaß" },
        { key: "FOOD", value: "Restaurants & Café" },
    ])

    const [flagReasons, setFlagReasons] = useState([
        { key: "OLD", value: "Veraltet" }, { key: "SPAM", value: "Unangemessener Inhalt / Spam" }, { key: "COPYRIGHT", value: "Urheberrechts verletzung" }, { key: "WRONG_INFO", value: "Falsche Information" }
    ])

    const id = props._id ? props._id : "noid";
    const [selectedCategory, setSelectedCategory] = useState(props.edit ? categories.find(el => el.key === props.category) : categories[0])
    const [title, setTitle] = useState(props.title ? props.title : "")
    const [description, setDescription] = useState(props.description ? props.description : "");
    const [priceFrom, setPriceFrom] = useState(props.minCosts ? props.minCosts.toString() : "0");
    const [priceTo, setPriceTo] = useState(props.maxCosts ? props.maxCosts.toString() : "0");
    const [number, setNumber] = useState(props.phone ? props.phone : "");
    const [additional, setAdditional] = useState(props.addiotionalDescription ? props.addiotionalDescription : "");
    const [email, setEmail] = useState(props.mail ? props.mail : "");
    const [website, setWebsite] = useState(props.website ? props.website : "");
    const [instagramUrl, setInstagramUrl] = useState(props.instagram ? props.instagram : "")
    const [facebookUrl, setfacebookUrl] = useState(props.facebook ? props.facebook : "")
    const [targetGroups, setTargetGroups] = useState([
        { key: "CHILD", value: "Kind" }, { key: "PARENTS", value: "Eltern" }, { key: "PREGNANCY", value: "Schwangerschaft" }
    ])
    const [selectedTargetGroups, setselectedTargetGroups] = useState(props.targetGroup ? new Set(targetGroups.filter(el => props.targetGroup.includes(el.key))) : new Set())
    const [Tags, setTags
    ] = useState([
        { name: 'INDOOR', displayname: "Indoor", active: false },
        { name: 'OUTDOOR', displayname: "Outdoor", active: false },
        { name: 'ANIMALS', displayname: "Tiere", active: false },
        { name: 'NATURE', displayname: "Natur", active: false },
        { name: 'SWIMMING', displayname: "Baden", active: false },
        { name: 'CREATIVITY', displayname: "Kreativität", active: false },
        { name: 'EDU', displayname: "Bildung", active: false },
        { name: 'BIRTHDAY', displayname: "Kindergeburtstag", active: false },
        { name: 'HIKING', displayname: "Wandern", active: false },
        { name: 'MOVE', displayname: "Bewegen", active: false },
        { name: 'SUMMER', displayname: "Sommer", active: false },
        { name: 'WINTER', displayname: "Winter", active: false },
        { name: 'BARRIER_FREE', displayname: "barrierefrei", active: false },
        { name: 'FFParking', displayname: "familienfreundliches Parken", active: false },
        { name: 'FREE_PARKING', displayname: "kostenloses Parken", active: false },
        { name: 'STROLLER_FRIENDLY', displayname: "Kinderwagen freundlich", active: false },
        { name: 'WIFI', displayname: "Wifi", active: false },
        { name: 'NAPPY_CHANGING_ROOM', displayname: "Wickelraum", active: false },
        { name: 'WC', displayname: "WC vorhanden", active: false },
        { name: 'SHADED', displayname: "Schattenplätze", active: false },
        { name: 'REST_AREA', displayname: "Rastplatz", active: false },
        { name: 'PUBLIC_TRANSPORT', displayname: "Öffentliche Verkehrsmittel", active: false },
        { name: 'CARE_FACILITIES', displayname: "Betreuungsmöglichkeit", active: false },
    ])

    const costsMode = {
        free: 1,
        hasCosts: 2,
        notSpecified: 3
    }


    const [selectedTags, setSelectedTags] = useState(props.tags ? new Set(Tags.filter(el => props.tags.includes(el.name))) : new Set())
    const [rerender, setRerender] = useState(false)
    const [locationCreator, setLocationCreator] = useState({})
    const [userLoaded, setUserLoaded] = useState({})

    const [hasCosts, setHasCosts] = useState(evaluateHasCosts())

    const [images, setImages] = useState([])
    let imagePreviewArray = new Array(10).fill(imagePlaceholder)

    const [imagePreview, setimagePreview] = useState(imagePreviewArray)
    const [hasAgeRange, setHasAgeRange] = useState(!selectedTargetGroups.has("CHILD"))
    const [age, setAge] = useState({ min: props.minAge ? props.minAge : 0, max: props.maxAge ? props.maxAge : 16 })

    const [uploading, setUploading] = useState(false)

    //ToDo/
    const [gLocation, setGLocation] = useState(null);
    // UI-Functions


    async function fetchUsers() {
        if (!props.edit)
            return

        let usersToLoad = [props.createdBy, ...props.flags]
        let userInfo = {}
        let Bearer = ""
        await getAccessTokenSilently().then(success => {
            Bearer = success
            setAccessToken(success)
        })
        let tmpUserLoaded = {}
        for (const userID of usersToLoad) {
            let url = baseUrl + '/admin//UserInfo/' + userID;
            await axios({
                method: 'get',
                url: url,
                headers: {
                    'Access-Control-Allow-Origin': baseUrl,
                    'Accept': 'application/json',
                    "Content-Type": "application/json; charset=utf-8",
                    'Authorization': 'Bearer ' + Bearer,
                }
            }).then((res) => {

                if (res.status === 200 || res.status === 201) {
                    console.log(userID)
                    tmpUserLoaded[userID] = res.data;
                }
                else {
                    console.log("Could not fetch user" + res.status + " " + res.error)
                }
            })
        }

        setUserLoaded(tmpUserLoaded)
        setRerender(!rerender)
    }

    const setActiveTags = async () => {
        for (let tag in props.tags) {
            Tags.find((elem) => elem.name === props.tags[tag]).active = true;
        }
    }

    useEffect(() => {
        console.log(prettifyDate(props.createdAt))
        const fetchImages = async () => {
            if (props.edit) {
                if (props.images && images.length <= 0) {
                    if (props.images.length > 0) {
                        images.push(...props.images)
                        for (let [index, image] of images.entries()) {
                            imagePreview[index] = image
                            console.log(index)
                        }


                        // let pics = await props.images.map(async (elem, index) => {
                        //     let url = baseUrl + "/" + elem;
                        //     let result = await axios({
                        //         method: 'get',
                        //         url: url,
                        //         responseType: 'blob',
                        //         headers: {
                        //             'Access-Control-Allow-Origin': "https://familyactivities.de/",
                        //         }
                        //     })
                        setRerender(!rerender)
                    }
                }
            }
        }
        if (props.edit) {
            setActiveTags();
            fetchImages();
            fetchUsers()

        }
    }, [])

    function evaluateHasCosts() {
        let hasCosts = costsMode.notSpecified;
        //default Guard Condition
        if (!props)
            return hasCosts

        if (props.maxCosts != undefined && props.minCosts != undefined)
            hasCosts = costsMode.hasCosts;

        if (props.price != undefined && props.price === 0)
            hasCosts = costsMode.free;

        return hasCosts;

    }
    function renderCategorieButtons() {
        const items = [];
        for (const [index, item] of categories.entries()) {
            items.push(<Button key={index} onClick={() => onCategorieButtonClick(item)} active={selectedCategory === item} >{item.value}</Button>)
        }
        return items;
    }

    // function renderTagButtons() {
    //     let items = tags.filter(v => v.category === selectedCategory?.key).map((v, index) => {
    //         return <Button key={index} onClick={() => onTagClicked(v)} active={v.active}>{v.displayname}</Button>

    //     });

    //     return items;
    // }
    function renderTagButtons() {
        const items = [];
        for (const [index, item] of Tags.entries()) {
            items.push(<Button key={index} onClick={() => onTagClicked(item)} active={item.active}>{item.displayname}</Button>)
        }
        return items;
    }
    function onTagClicked(item) {
        item.active = !item.active
        setRerender(!rerender)
        return

        // if (selectedTags.has(item)) {

        //     selectedTags.delete(item)
        //     elem.target.active = false
        // }
        // else {
        //     elem.target.active = true;
        //     selectedTags.add(item)
        // }
        // setRerender(!rerender)
    }

    function renderTargetgroupsButtons() {
        const items = [];
        for (const [index, item] of targetGroups.entries()) {
            items.push(<Button key={index} onClick={(e) => onTargetGroupButtonClick(e)} active={selectedTargetGroups.has(item)} >{item.value}</Button>)
        }
        return items;
    }

    const removeFlags = async (id) => {
        let Bearer = ""
        await getAccessTokenSilently().then(success => {
            Bearer = success
            setAccessToken(success)
        })

        await axios({
            method: 'delete',
            url: baseUrl + `/location/flag/${id}`,
            headers: {
                'Access-Control-Allow-Origin': baseUrl,
                'Accept': 'application/json',
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': 'Bearer ' + Bearer,
            }
        }).then((res) => {

            if (res.status === 200 || res.status === 201) {
                console.log(res.message)
                window.location.reload()
            }
            else {
                console.log("Could not remove Flags" + res.status + " " + res.error)
            }
        })
    }

    function renderFlagReasons() {
        const items = [];
        console.log(props.flagsWithReason)
        for (const [index, item] of props.flagsWithReason.entries()) {

            items.push(
                <div>
                    <div className="one_column_container_column" style={{ marginBottom: "40px" }}>
                        <Label ><b>Meldung {index}</b></Label>
                        <div>
                            <Label>Gemeldet von: <u><b style={{ marginLeft: "10px" }}>
                                {
                                    userLoaded[props.flags[index]] != undefined ? userLoaded[props.flags[index]].email : ""
                                }
                            </b></u></Label>
                            <img onClick={() => { navigator.clipboard.writeText(props.createdBy) }} src={copy} width="25" style={{ marginLeft: "10px", cursor: "pointer" }}></img>
                        </div>
                        <ButtonGroup>
                            <div id="Reasons" className={"reason_container_buttonbar"} style={{ width: "100%" }}>
                                {renderReasons(item.reason)}
                            </div>
                        </ButtonGroup>
                        <Label style={{ marginTop: "20px" }}><b>Beschreibung </b> </Label>
                        <textarea value={item.message} readOnly></textarea>
                    </div>
                    <hr />
                </div>
            )
        }

        return items;
    }


    function renderReasons(reason) {
        const items = [];
        for (const [index, item] of flagReasons.entries()) {
            items.push(<Button key={index} active={item.key === reason} onClick={() => { }}>{item.value}</Button>)
        }
        return items;
    }

    function onCategorieButtonClick(rSelected) {
        setSelectedCategory(rSelected)
    }


    function onTargetGroupButtonClick(elem) {
        const item = targetGroups.find(e => e.value === elem.currentTarget.innerText);

        if (selectedTargetGroups.has(item)) {
            selectedTargetGroups.delete(item)
            elem.target.active = false;
        }
        else {
            elem.target.active = true;
            selectedTargetGroups.add(item)
        }
        setHasAgeRange(selectedTargetGroups.has(targetGroups.find(el => el.key === "CHILD")))
        setRerender(!rerender)
    }

    function decodePlaceId(value) {
        geocodeByPlaceId(value.value.place_id)
            .then(result => {
                setLocation(
                { display_name: result[0].formatted_address, lat: result[0].geometry.location.lat(), lng: result[0].geometry.location.lng() })
            })
    }
    //ToDo pack on top
    const [newImages, setNewImages] = useState([])

    async function selectedFileChanged(event) {
        event.stopPropagation();
        event.preventDefault();
        const image = event.target.files[0];
        try {
            imagePreview.forEach((v, i) => {
                if (imagePreview[i] === imagePlaceholder) {
                    imagePreview[i] = URL.createObjectURL(image)

                    newImages.push({ "image": image, "url": imagePreview[i] })
                    throw "break"
                }
            })
        } catch (error) {

        }
        setRerender(!rerender)
    }
    function toggleAlert() {
        setIsAlertOpen(!isAlertOpen)
    }

    function parseImagePath(urls) {
        // Finde den Index des /uploads/ Teils in der URL
        const newUrls = urls.map((url) => {
            const uploadPathIndex = url.indexOf('uploads/');
            const endUploadPathIndex = url.indexOf('?')
            // Wenn /uploads/ nicht gefunden wurde, gib eine leere Zeichenkette zurück
            if (uploadPathIndex === -1) {
                return '';
            }

            // Extrahiere den Teil der URL ab /uploads/
            return url.substring(uploadPathIndex, endUploadPathIndex);
        })
        return newUrls;
    }

    // Beispielnutzung


    async function createFormData() {
        const locationTags = []

        Tags.filter(item => item.active === true).forEach((elem, index) => {
            locationTags.push(elem.name)
        })

        const priceFromCalculated = hasCosts != costsMode.free ? priceFrom.replace(',', '.') : 0;
        const priceToCalculated = hasCosts != costsMode.free ? priceTo.replace(',', '.') : 0;

        const imageArray = [...parseImagePath(images)]
        if (newImages.length != 0)
            imageArray.push(...await uploadImages(newImages))
        //push existing images
        const targetGroupArray = []
        Array.from(selectedTargetGroups).sort().forEach((elem, index) => {
            targetGroupArray.push(elem.key);
        })
        // imageInformation.forEach(image => {
        //     if (image != null) {
        //         imageArray.push(image.filename)
        //     }
        // })
        const newLocation = {
            "location": [location.lng, location.lat],
            "meta": {
                "highlightMarker": 0,
                "detailPage": 1
            },
            "targetGroup": targetGroupArray.length != 0 ? targetGroupArray : [],
            "tags": locationTags.length != 0 ? locationTags : [],
            "images": imageArray.length != 0 ? imageArray : [],
            "title": title,
            "category": selectedCategory.key,
            "description": description,
            "addiotionalDescription": additional,
            "phone": number,
            "mail": email,
            "website": website,
            "instagram": instagramUrl,
            "facebook": facebookUrl,
            "minAge": hasAgeRange ? age.min : 0,
            "maxAge": hasAgeRange ? age.max : 0,
            "minCosts": hasCosts != costsMode.notSpecified ? priceFromCalculated : "",
            "maxCosts": hasCosts != costsMode.notSpecified ? priceToCalculated : "",
            "price": hasCosts === costsMode.free ? 0 : priceFromCalculated <= 0 && priceToCalculated >= 0 ? Number(priceToCalculated) : "",
            "locationString": location.display_name,
            "published": true
        }
        return newLocation
    }

    function toggleDelete() {
        setIsDeleteOpen(!isDeleteOpen)
    }

    function validate() {
        let message = {}
        if (selectedTargetGroups.size === 0) message = { title: "Zielgruppe wurde nicht eingebene", message: "Die Zielgruppe ist ein Pflichtfeld, bitte geben sie diese an bevor Sie eine neue Location anlegen." }
        if (Object.keys(location).length === 0) message = { title: "Standort wurde nicht angegeben", message: "Der Standort ist eine Pflichteingabe , bitte geben sie diese an bevor Sie eine neue Location anlegen." }
        if (description == null || description.length === 0) message = { title: "Beschreibung wurde nicht eingebene", message: "Die Beschreibung ist ein Pflichteingabe, bitte geben sie diese an bevor Sie eine neue Location anlegen." }
        if (title == null || title.length === 0) message = { title: "Title wurde nicht eingebene", message: "Der Titel ist ein Pflichteingabe, bitte geben sie diesen an bevor Sie eine neue Location anlegen." }
        if (selectedCategory == null) message = { title: "Kategorie wurde nicht ausgewählt", message: "Die Kategorie ist eine Pflichteingabe, bitte wählen sie diese aus ,bevor Sie eine neue Location anlegen." }
        //if (props.edit === true) message = { title: "Not yer Implemented", message: "Dieses Feature wurde leider noch nicht implementiert" }

        if (Object.keys(message).length != 0) {
            setAlert(message);
            toggleAlert();
            return false;
        }
        return true;
    }
    function handleSubmit() {
        if (!props.edit)
            return handleSubmitCreate()
        return handleSubmitUpdate();
    }
    async function uploadImageToAzure(imageinformation, image) {
        const sasUrl = imageinformation.sasToken; 
        const uploadName = imageinformation.filename;
        try {
            const blobClient = new BlockBlobClient(sasUrl);
            console.log(blobClient)
            console.log(image)
            await blobClient.uploadData(image);
            console.log(image)
            return uploadName
        } catch (error) {
            console.error("Image Upload fehlgeschlagen:", error);
        } finally {
            //setUploading(false);
        }

        return ""
    }


    async function uploadImages(images) {
        let Bearer = ""
        await getAccessTokenSilently().then(success => {
            Bearer = success
            setAccessToken(success)
        })
        const res = await axios({
            method: 'get',
            url: baseUrl + '/location/getUploadUrl?count=' + images.length,
            headers: {
                'Accept': 'application/json',
                "Content-Type": "application/json; charset=utf-8",
                'Access-Control-Allow-Origin': baseUrl,
                'Authorization': 'Bearer ' + Bearer,
            }

        })
        const imageToUpload = []
        for (const img of images) {
            imageToUpload.push(img.image)
        }

        if (res) {
            if (res.status === 200 || res.status === 201) {
                const ImageInformation = res.data
                const fileNames = []
                console.log(JSON.stringify(ImageInformation))
                for (const [index, element] of ImageInformation.entries()) {
                    const filename = await uploadImageToAzure(element, imageToUpload[index]);
                    fileNames.push(filename)
                }
                return fileNames
            }
            else {
                console.log("Could not create location {} " + res.status + " " + res.error)
                setUploading(false)
            }
        }
    }

    async function handleSubmitCreate() {
        if (!validate()) return
        setUploading(true)
        const formData = await createFormData();
        getAccessTokenSilently().then(success => {
            console.log("got Bearar Token")
            setAccessToken(success)
            axios({
                method: 'post',
                url: baseUrl + '/location',
                data: formData,
                headers: {
                    'Accept': 'application/json',
                    "Content-Type": "application/json; charset=utf-8",
                    'Access-Control-Allow-Origin': baseUrl,
                    'Authorization': 'Bearer ' + success,
                }

            }).then((res) => {
                if (res.status === 200 || res.status === 201) {
                    console.log("Created location successfully")
                    setUploading(false)
                    window.location.reload();
                }
                else {
                    console.log("Could not create location {} " + res.status + " " + res.error)
                    setUploading(false)
                }
            })
        })
    }

    async function handleSubmitUpdate() {
        if (!validate()) return
        const formData = await createFormData();
        setUploading(true)
        getAccessTokenSilently().then(success => {
            console.log("got Bearar Token")
            setAccessToken(success)
            axios({
                method: 'post',
                url: baseUrl + '/location/' + id,
                data: formData,
                headers: {
                    'Accept': 'application/json',
                    "Content-Type": "application/json; charset=utf-8",
                    'Access-Control-Allow-Origin': baseUrl,
                    'Authorization': 'Bearer ' + success,
                }

            }).then((res) => {
                if (res.status === 200 || res.status === 201) {
                    console.log("Updated location successfully")
                    setUploading(false)
                    props.reload()
                }
                else {
                    console.log("Updated not create location {} " + res.status + " " + res.error)
                    setUploading(false)
                }
            })
        })
    }

    //Edit Operations
    const onTrashbinClicked = (url, id) => {
        if (images.includes(url)) {
            const index = images.indexOf(url)
            images.splice(index, 1)

        }
        else {
            for (const img of newImages) {
                if (img.url == url) {
                    const index = newImages.indexOf(img)
                    newImages.splice(index, 1)
                }
            }
        }
        imagePreview[id] = imagePlaceholder

        setRerender(!rerender)
    }

    const renderImages = (amount) => {
        let items = []
        console.log(amount)
        for (let i = 0; i < amount; i++) {
            items.push(<ImageContainer imageUrl={imagePreview[i]} key={i} id={i} onTrashbinClicked={onTrashbinClicked} />)
        }
        return items
    }

    function deleteLocation() {
        if (!props)
            return
        let body = { id: props.location._id, createdBy: props.location.createdBy }

        let url = baseUrl + '/location/' + props._id
        if (isUserAdmin(user))
            url = baseUrl + '/admin/location/' + props._id

        getAccessTokenSilently().then(success => {
            console.log("got Bearar Token")
            setAccessToken(success)
            axios({
                method: 'delete',
                url: url,
                data: body,
                headers: {
                    'Accept': 'application/json',
                    "Content-Type": "application/json; charset=utf-8",
                    'Access-Control-Allow-Origin': baseUrl,
                    'Authorization': 'Bearer ' + success,
                }

            }).then((res) => {
                if (res.status === 200 || res.status === 201) {
                    console.log("delete location successfully")
                    window.location.reload();
                }
                else {
                    console.log("Could not delete location {} " + res.status + " " + res.error)
                }
            })
        })
        //toggleDelete()
    }

    const prettifyDate = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = date.toLocaleString('de-DE', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
        return formattedDate
    }

    return (
        <div>
            {isAuthenticated && isUserAuthorized(user) &&
                <form>
                    <Modal isOpen={isAlertOpen} toggle={toggleAlert} >
                        <ModalHeader toggle={toggleAlert}>{alert.title}</ModalHeader>
                        <ModalBody>{alert.message}</ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={toggleAlert}>OK</Button>
                        </ModalFooter>
                    </Modal>

                    <Modal isOpen={isDeleteOpen} toggle={toggleDelete} >
                        <ModalHeader toggle={toggleDelete}>Wirklich Löschen?</ModalHeader>
                        <ModalBody>Sind sie wirklich sicher, dass sie diese Location löschen wollen? Diese Aktion ist nicht mehr rückgängig zumachen</ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={deleteLocation}>Löschen!</Button>
                            <Button color="primary" onClick={toggleDelete}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                    {
                        props.edit && props.createdBy in userLoaded &&
                        <div className="one_column_container_column">
                            <div className="location_header">
                                <Label><b>Erstellt von </b>{userLoaded[props.createdBy].email}</Label>
                                <Label><b>Erstellt am</b> {prettifyDate(props.createdAt)}</Label>
                            </div>
                            <hr />
                            <Label>Aufrufe: {props.meta.detailPage}</Label>
                            <Label>Angeklickt: {props.meta.highlightMarker}</Label>

                            <hr></hr>
                        </div>
                    }

                    {
                        props.edit && props.flags.length > 0 && props.flags[0] in userLoaded &&

                        <div className="one_column_container_row" style={{ marginBottom: "20px" }}>
                            <Label><b>{props.flags.length} {props.flags.length > 1 ? "Meldungen" : "Meldung"}</b></Label>
                            <Button className="flag_remove_Button" onClick={() => { removeFlags(id) }}>Meldungen Löschen</Button>
                        </div>
                    }

                    {props.edit && props.flagsWithReason.length > 0 &&
                        renderFlagReasons()
                    }


                    <div className="location_container_row" >
                        <Loading hidden={!uploading}></Loading>
                        <div className="location_container_column" style={{ paddingRight: "30px" }} >
                            <div className="default_category_container">
                                <Label for="categorieChooser">Kategorien *</Label>
                                <ButtonGroup>
                                    <div id="categorieChooser" className={selectedCategory != null ? "default_container_buttonbar" : "default_container_buttonbar_missing"}>
                                        {renderCategorieButtons()}
                                    </div>
                                </ButtonGroup>
                            </div>
                            <div className="default_container">
                                <Label for="input_Title">Titel *</Label>
                                <Input type="text" className={title.length > 0 ? "" : "input_field_missing_focus"} id="input_Title" placeholder="Titel eingeben" value={title} onChange={(e) => setTitle(e.target.value)} onPaste={(e) => {
                                    e.preventDefault();
                                    setTitle(title + e.clipboardData.getData('text/plain').normalize("NFKD"))
                                }}></Input>
                            </div>
                            <div className="default_container">
                                <Label for="textarea_Description">Beschreibung *</Label>
                                <textarea className={description.length > 0 ? "" : "input_field_missing_focus"} id="textarea_Description" value={description} placeholder="Beschreibung eingeben" onChange={(e) => { setDescription(e.target.value) }}
                                    onPaste={(e) => {
                                        e.preventDefault();
                                        setDescription(description + e.clipboardData.getData('text/plain').normalize("NFKD"))
                                    }} style={{ height: 450 }}></textarea>
                            </div>
                            <div className="autcomplete_container">
                                <Label for="input_location">Standort *</Label>
                                {props.edit && <p>{location.display_name}</p>}
                                <div className={Object.keys(location).length != 0 ? "" : "places_missing"} style={{ width: "100%" }}>
                                    <PlacesAutocomplete
                                        selectProps={{ gLocation, onChange: decodePlaceId }}
                                        apiKey="AIzaSyDQ_2e2I1x9wphBEO_bACNQsNTuUKeYfng"
                                        apiOptions={{ language: "de", region: "de" }}
                                        autocompletionRequest={{
                                            componentRestrictions: {
                                                country: ["de"],
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="default_container">
                                <Label >Zielgruppe *</Label>
                                <ButtonGroup>
                                    <div id="targetGroupChooser" className={selectedTargetGroups.size != 0 ? "targetgroup_container_buttonbar" : "targetgroup_container_buttonbar_missing"}>
                                        {renderTargetgroupsButtons()}
                                    </div>
                                </ButtonGroup>

                                <div className="default_container" >
                                    <Label disabled={selectedTargetGroups.has(targetGroups.find(el => el.key === "CHILD")) ? false : true} style={{ marginBottom: 10 }}>Alter</Label>
                                    <InputRange
                                        maxValue={16}
                                        minValue={0}
                                        value={age}
                                        onChange={(value) => { setAge(value) }}
                                        disabled={selectedTargetGroups.has(targetGroups.find(el => el.key === "CHILD")) ? false : true}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className="location_container_column">
                            <div className="default_container">
                                <Label for="textarea_additional">Zusätzliche Informationen</Label>
                                <textarea id="textarea_additional" placeholder="Informationen eingeben" value={additional} onChange={(e) => { setAdditional(e.target.value) }} onPaste={(e) => {
                                    e.preventDefault();
                                    setAdditional(e.clipboardData.getData('text/plain').normalize("NFKD"))
                                }} ></textarea>
                            </div>

                            <div className="default_container">
                                <Label >Telefonnummer</Label>
                                <Input type="tel" value={number} name="phone" id="input_number" placeholder="Telefonnummer eingeben" onChange={(e) => { setNumber(e.target.value) }}
                                    onPaste={(e) => {
                                        e.preventDefault();
                                        setNumber(number + e.clipboardData.getData('text/plain').normalize("NFKD"))
                                    }} ></Input>
                            </div>
                            <div className="default_container">
                                <Label >E-Mail</Label>
                                <Input type="email" name="email" value={email} id="input_email" placeholder="Email eingeben" onChange={(e) => { setEmail(e.target.value) }}
                                    onPaste={(e) => {
                                        e.preventDefault();
                                        setEmail(email + e.clipboardData.getData('text/plain').normalize("NFKD"))
                                    }}></Input>
                            </div>
                            <div className="default_container">
                                <Label >Website</Label>
                                <Input id="input_webside" value={website} placeholder="Website eingeben" onChange={(e) => { setWebsite(e.target.value) }}
                                    onPaste={(e) => {
                                        e.preventDefault();
                                        setWebsite(website + e.clipboardData.getData('text/plain').normalize("NFKD"))
                                    }}></Input>
                            </div>
                            <div className="default_container">
                                <span>
                                    <Label>Instagram</Label><img src={InstagramIcon} width="3%" style={{ marginLeft: "20px" }} />
                                </span>
                                <Input value={instagramUrl} placeholder="Instagram Link eingeben" onChange={(e) => { setInstagramUrl(e.target.value) }}
                                    onPaste={(e) => {
                                        e.preventDefault();
                                        setInstagramUrl(instagramUrl + e.clipboardData.getData('text/plain').normalize("NFKD"))
                                    }}></Input>
                            </div>
                            <div className="default_container">
                                <div>
                                    <Label >Facebook</Label><img src={FacebookIcon} width="1.5%" style={{ marginLeft: "15px" }}
                                    />
                                </div>
                                <Input value={facebookUrl} placeholder="Facebook Link eingeben" onChange={(e) => { setfacebookUrl(e.target.value) }}
                                    onPaste={(e) => {
                                        e.preventDefault();
                                        setfacebookUrl(facebookUrl + e.clipboardData.getData('text/plain').normalize("NFKD"))
                                    }} ></Input>
                            </div>
                            <div className="default_category_container">
                                <Label for="TagChooser">Tags</Label>
                                <ButtonGroup>
                                    <div id="TagChooser" className="default_container_buttonbar">
                                        {renderTagButtons()}
                                    </div>
                                </ButtonGroup>
                            </div>
                            <div className="default_container">
                                <Label for="costs">Kosten</Label>
                                <div id="costs" >
                                    <div id="has_costs" className="container_costs">
                                        <ButtonGroup style={{ width: "100%" }}>
                                            <Button onClick={() => setHasCosts(costsMode.notSpecified)} active={hasCosts == costsMode.notSpecified} variant="btn-container_costs_left">Keine Angabe</Button>
                                            <Button onClick={() => setHasCosts(costsMode.hasCosts)} active={hasCosts == costsMode.hasCosts} variant="btn-container_costs_left">Preisspanne</Button>
                                            <Button onClick={() => setHasCosts(costsMode.free)} active={hasCosts == costsMode.free} variant="btn-container_costs_right" >Kostenlos</Button>
                                        </ButtonGroup>

                                    </div>
                                    <div className="location_container_row">
                                        <Label for="input_price_from">Von:</Label>
                                        <CurrencyInput
                                            placeholder="Ab"
                                            disabled={hasCosts === costsMode.free || hasCosts === costsMode.notSpecified}
                                            value={priceFrom}
                                            decimalSeparator=","
                                            decimalScale={2}
                                            decimalsLimit={2}
                                            disableAbbreviations={true}
                                            onValueChange={(e, i) => { setPriceFrom(e) }}
                                            prefix="€" />

                                        <Label for="input_price_to">Bis:</Label>
                                        <CurrencyInput
                                            placeholder="Ab"
                                            disabled={hasCosts === costsMode.free || hasCosts === costsMode.notSpecified}
                                            value={priceTo}
                                            disableAbbreviations={true}
                                            decimalSeparator=","
                                            decimalScale={2}
                                            decimalsLimit={2}
                                            onValueChange={(e, i) => { setPriceTo(e) }}
                                            prefix="€" />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                    <div className="default_container">
                        <div className="image_container">
                            {renderImages(10)}
                        </div>
                        <div className="upload_image_Container">
                            <input type='file' id='file' onChange={selectedFileChanged} ref={inputFile} style={{ display: 'none' }} />
                            {(!props.edit || isUserAdmin(user) || props.createdBy === user.sub) &&
                                <Button onClick={() => { inputFile.current.click() }} className="upload_image_button">+</Button>
                            }
                        </div>
                    </div>
                    <hr />
                    <div className="location_container_row">
                        <div style={{ textAlign: "left", width: "100%" }}>
                            * Pflichtfeld
                        </div>
                        {props.edit &&
                            <div className="location_container_column">
                                <div className="delete_button_container">
                                    <Button id="delete" onClick={toggleDelete}>Löschen</Button>
                                </div>
                            </div>
                        }

                        {!props.edit &&
                            <div className="location_container_column">
                            </div>
                        }
                        <div className="location_container_column">
                            <div className="submit_button_container">
                                <Button id="Submit" onClick={handleSubmit}> Eintrag speichern</Button>
                            </div>
                        </div>
                    </div>

                </form >
            }
        </div >
    )
    // Styles


};

//inline Styles = 
const imageStyle = {
    flex: 1
}

export default CreateLocationComponent;
