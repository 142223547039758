import React, { useEffect, useState, useCallback, useRef } from 'react';
import "../Styles/AdsView.css"
import 'bootstrap/dist/css/bootstrap.min.css'
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import CustomeMap from './CustomeMap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Standard CSS für Datepicker
import { BlockBlobClient } from '@azure/storage-blob';

const AdView = ({ campaign, company, reload }) => {

    const baseUrl = process.env.REACT_APP_SERVER
    const [ads, setAds] = useState(campaign.ads ? campaign.ads : [])
    const [bannerAd, setBannerAd] = useState({})
    const [carouselAd, setCarouselAd] = useState({})
    const [accessToken, setAccessToken] = useState("");
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)
    const [targetTypes, setTargetTypes] = useState([
        { key: "WEB", value: "Web" },
        { key: "LOCATION", value: "Location" },
        { key: "INSTAGRAM", value: "Instagram" },
        { key: "FACEBOOK", value: "Facebook" },
        { key: "WHATSAPP", value: "Whatsapp" },
    ])
    const AdType = Object.freeze({
        BANNER: 'BANNER',
        CAROUSEL: 'CAROUSEL'
    });

    const [range, setRange] = useState(2000)
    const [frequency, setfrequency] = useState(0.1)
    const [target, setTarget] = useState("")

    const [selectedTargetType, setSelectedTargetType] = useState(targetTypes[0])

    const [location, setLocation] = useState({ lat: 48.78232, lng: 9.17702 })
    const AdBannerInputFile = useRef()
    const AdCarouselInputFile = useRef()
    const [isInfoOpen, setIsInfoOpen] = useState(false)
    const [info, setInfo] = useState(false)
    const [bannerAdImage, setbannerAdImage] = useState({})
    const [carouselAdImage, setcarouselAdImage] = useState({})
    const [deleteRequest, setDeleteRequest] = useState({})
    const Types = {
        BANNER: "banner",
        CAROUSEL: "carousel",
    }


    //#region  Auth0
    const {
        user,
        getAccessTokenSilently,
        isAuthenticated,
        loginWithRedirect,
        logout,
    } = useAuth0();

    //#region Use Effects
    useEffect(() => {
        if (isAuthenticated) {
            const getToken = async () => {
                let accessToken = ""
                accessToken = await getAccessTokenSilently();
                setAccessToken(accessToken);
            }
            try {
                getToken();
            }
            catch (e) {
                console.log(e)
            }
        }
    }, [isAuthenticated])

    const generateImageObject = (url, name, changed, image) => {
        const ret = {
            url: url,
            name: name,
            changed: changed,
        }

        ret.imageObject = image
        return ret
    }

    useEffect(() => {
        if (bannerAd.location) {
            setLocation({ lat: bannerAd.location.coordinates[1], lng: bannerAd.location.coordinates[0] })
            setRange(bannerAd.range)
            setfrequency(bannerAd.frequency)
            setTarget(bannerAd.target)
            setSelectedTargetType(targetTypes.find(el => el.value == bannerAd.targetType))
            const imageObject = generateImageObject(bannerAd.imageUrl, bannerAd.imageUrl, false, null)
            setbannerAdImage(imageObject)
        }

        if (carouselAd.location) {
            setLocation({ lat: carouselAd.location.coordinates[1], lng: carouselAd.location.coordinates[0] })
            setRange(carouselAd.range)
            setfrequency(carouselAd.frequency)
            setTarget(carouselAd.target)
            setSelectedTargetType(targetTypes.find(el => el.value == carouselAd.targetType))
            const imageObject = generateImageObject(carouselAd.imageUrl, carouselAd.imageUrl, false, null)
            setcarouselAdImage(imageObject)
        }

    }, [bannerAd, carouselAd])

    useEffect(() => {
        setAds(campaign.ads ? campaign.ads : [])
        console.log("AdView")
        console.log(campaign.ads)
    }, [campaign])

    useEffect(() => {
        loadAds()
    }, [ads])

    const loadAds = async () => {
        if (!accessToken || !isAuthenticated)
            return

        if (ads.banner)
            await getAdbyID(ads.banner, Types.BANNER)
        else {
            setBannerAd({})
            setbannerAdImage({})
        }
        if (ads.carousel)
            await getAdbyID(ads.carousel, Types.CAROUSEL)
        else {
            setCarouselAd({})
            setcarouselAdImage({})
        }

    }

    useEffect(() => {
        loadAds()
    }, [accessToken])


    const getAdbyID = async (id, type) => {
        if (!accessToken)
            return

        const url = baseUrl + '/admin/ads/' + id

        await axios({
            method: 'get',
            url: url,
            headers: {
                'Accept': 'application/json',
                "Content-Type": "application/json; charset=utf-8",
                'Access-Control-Allow-Origin': baseUrl,
                'Authorization': 'Bearer ' + accessToken,
            }

        }).then((res) => {
            if (res.status === 200 || res.status === 201) {
                if (type === Types.BANNER)
                    setBannerAd(res.data)

                else if (type === Types.CAROUSEL)
                    setCarouselAd(res.data)
                else
                    throw "Not supported Ad Type"

            }
            else {
                console.log("Could not delete location {} " + res.status + " " + res.error)
            }
        })
    }
    //#region  render Helper
    const renderTargetTypes = () => {
        const items = [];
        for (const [index, item] of targetTypes.entries()) {
            items.push(<Button key={index} className='selectButton'
                onClick={() => {
                    setSelectedTargetType(item)
                }} active={selectedTargetType.key === item.key} >{item.value}
            </Button>)
        }
        return items
    }

    function parseImagePath(url) {
        const publicConstant = "/public/"
        const uploadPathIndex = url.indexOf('ads/');
        const endUploadPathIndex = url.indexOf('?')
        if (uploadPathIndex === -1) {
            return '';
        }

        return publicConstant + url.substring(uploadPathIndex, endUploadPathIndex);

    }

    const onLocationChanged = (lat, lng) => {
        setLocation({ lat: lat, lng: lng })
    }

    async function uploadImageToAzure(sasUrl, image) {
        try {
            const blobClient = new BlockBlobClient(sasUrl);
            await blobClient.uploadData(image, {
                blobHTTPHeaders: {
                    blobContentType: "image/jpeg"
                }
            });
        } catch (error) {
            console.error("Image Upload fehlgeschlagen:", error);
        } finally {
        }

    }

    async function uploadImage(image) {
        let Bearer = ""
        await getAccessTokenSilently().then(success => {
            Bearer = success
            setAccessToken(success)
        })

        const res = await axios({
            method: 'get',
            url: baseUrl + '/admin/getAdUploadUrl?name=' + image.name,
            headers: {
                'Accept': 'application/json',
                "Content-Type": "application/json; charset=utf-8",
                'Access-Control-Allow-Origin': baseUrl,
                'Authorization': 'Bearer ' + Bearer,
            }

        })

        if (res) {
            if (res.status === 200 || res.status === 201) {
                const sasToken = res.data

                console.log("uploadImage")
                await uploadImageToAzure(sasToken, image.imageObject)
            }
        }


    }

    const createAdData = (ad, imageUrl) => {
        const adObject = {
            lat: location.lat,
            lng: location.lng,
            range: range,
            _id: ad._id,
            target: target,
            targetType: selectedTargetType.value,
            frequency: frequency,
            company: ad.company,
            adType: ad.adType,
            createAt: ad.createdAt,
            campaign: ad.campaign,
            imageUrl: imageUrl
        }
        return adObject;
    }

    const NewAdData = (type, imageUrl) => {
        const adObject = {
            lat: location.lat,
            lng: location.lng,
            range: range,
            target: target,
            targetType: selectedTargetType.value,
            frequency: frequency,
            company: company.name,
            adType: type,
            campaign: campaign.title,
            imageUrl: imageUrl
        }
        return adObject;
    }

    const validate = () => {

        if (!range || range == "" || range <= 500) {
            showInfo("Die Range muss mindestens 1000 m betragen")
            return false
        }
        if (!frequency || frequency == "") {
            showInfo("Frequency darf nicht leer sein")
            return false
        }
        if (frequency > 1) {
            showInfo("Frequency muss eine Gleitkoma zwahl zwischen 0.9 und 0.1 sein")
            return false
        }
        if (!target || target == "") {
            showInfo("Target darf nicht leer sein")
            return false
        }

        return true
    }

    const handleSaveAd = async () => {
        if (!validate())
            return

        try {
            if (Object.keys(bannerAd).length == 0 && bannerAdImage && bannerAdImage.changed) {
                let imageUrl = ""
                await uploadImage(bannerAdImage)
                imageUrl = "public/ads/" + bannerAdImage.name;
                const bannerAdData = NewAdData(AdType.BANNER, imageUrl)
                const id = await executePostAd(bannerAdData)
                await executeAddAdToCampaign({ bannerID: id })
                showInfo("Anzeige erfolgreich hinzugefügt")
            }
            else {
                let imageUrl = ""
                if (Object.keys(bannerAd).length !== 0) {
                    if (!bannerAdImage.changed)
                        imageUrl = parseImagePath(bannerAdImage.url);
                    else {
                        await uploadImage(bannerAdImage)
                        imageUrl = "public/ads/" + bannerAdImage.name;
                        //ToDo Upload Image
                    }

                    const bannerAdData = createAdData(bannerAd, imageUrl)
                    await executeUpdateAd(bannerAdData)
                    showInfo("Anzeige erfolgreich geändert")
                }
            }


            if (Object.keys(carouselAd).length == 0 && carouselAdImage && carouselAdImage.changed) {
                let imageUrl = ""
                await uploadImage(carouselAdImage)
                imageUrl = "public/ads/" + carouselAdImage.name;
                const carouselAdData = NewAdData(AdType.CAROUSEL, imageUrl)
                const id = await executePostAd(carouselAdData)
                await executeAddAdToCampaign({ carouselID: id })
                showInfo("Anzeige erfolgreich hinzugefügt")
            }
            else {
                let imageUrl = ""
                if (Object.keys(carouselAd).length !== 0) {
                    if (!carouselAdImage.changed)
                        imageUrl = parseImagePath(carouselAdImage.url);
                    else {

                        await uploadImage(carouselAdImage)
                        imageUrl = "public/ads/" + carouselAdImage.name;
                    }

                    const carouselAdData = createAdData(carouselAd, imageUrl)
                    await executeUpdateAd(carouselAdData)
                    showInfo("Anzeige erfolgreich geändert")
                }
            }
        }
        catch (err) {
            showInfo("Etwas ist schiefgegangne \n" + err)
            console.log(err)
        }
        await reload()
    }

    async function executeUpdateAd(ad) {
        const url = baseUrl + '/admin/ads/' + ad._id

        await axios({
            method: 'patch',
            url: url,
            data: ad,
            headers: {
                'Accept': 'application/json',
                "Content-Type": "application/json; charset=utf-8",
                'Access-Control-Allow-Origin': baseUrl,
                'Authorization': 'Bearer ' + accessToken,

            }

        }).then((res) => {
            if (res.status === 200 || res.status === 201) {
            }
            else {
                console.log("Could not delete location {} " + res.status + " " + res.error)
                throw (res.error)
            }
        })

    }

    async function executePostAd(ad) {
        const url = baseUrl + '/admin/ads/'

        const res = await axios({
            method: 'post',
            url: url,
            data: ad,
            headers: {
                'Accept': 'application/json',
                "Content-Type": "application/json; charset=utf-8",
                'Access-Control-Allow-Origin': baseUrl,
                'Authorization': 'Bearer ' + accessToken,

            }

        })
        if (res) {
            if (res.status === 200 || res.status === 201) {
                return res.data.id
            }
            else {
                console.log("Could not delete location {} " + res.status + " " + res.error)
                throw (res.error)
            }
        }

    }
    //create New Ad
    async function executeAddAdToCampaign(ad) {
        const url = baseUrl + `/admin/advertieser/${company._id}/campaign/${campaign.title}`

        const res = await axios({
            method: 'patch',
            url: url,
            data: ad,
            headers: {
                'Accept': 'application/json',
                "Content-Type": "application/json; charset=utf-8",
                'Access-Control-Allow-Origin': baseUrl,
                'Authorization': 'Bearer ' + accessToken,
            }
        })

        if (res) {
            if (res.status === 200 || res.status === 201) {
            }
            else {
                console.log("Could not delete location {} " + res.status + " " + res.error)
                throw (res.error)
            }
        }

    }

    async function executeDeleteAd(ad) {
        const url = baseUrl + `/admin/ads/${ad._id}`

        const params = {
            advertieserId: company._id,
            campaign: campaign.title
        }

        const res = await axios({
            method: 'delete',
            url: url,
            data: params,
            headers: {
                'Accept': 'application/json',
                "Content-Type": "application/json; charset=utf-8",
                'Access-Control-Allow-Origin': baseUrl,
                'Authorization': 'Bearer ' + accessToken,
            }
        })

        if (res) {
            if (res.status === 200 || res.status === 201) {
                console.log("Successfull deleted Ad")
            }
            else {
                console.log("Could not delete Ad {} " + res.status + " " + res.error)
                console.log("Not Successfull")
                throw (res.error)
            }
        }

    }

    async function executeDeleteCampaign() {
        const url = baseUrl + `/admin/advertiser/${company._id}/${campaign.title}`

        const res = await axios({
            method: 'delete',
            url: url,
            headers: {
                'Accept': 'application/json',
                "Content-Type": "application/json; charset=utf-8",
                'Access-Control-Allow-Origin': baseUrl,
                'Authorization': 'Bearer ' + accessToken,
            }
        })

        if (res) {
            if (res.status === 200 || res.status === 201) {
                alert("Successfull deleted campaign")
            }
            else {
                console.log("Could not delete campaign " + res.status + " " + res.error)
                alert("Not Successfull")
                throw (res.error)
            }
        }

    }
    async function selectedBannerAdImageChanged(event) {
        event.stopPropagation();
        event.preventDefault();
        const image = event.target.files[0];
        console.log(image.name)
        try {
            const imageObject = generateImageObject(URL.createObjectURL(image), image.name, true, image)
            setbannerAdImage(imageObject)
        } catch (error) {

        }
    }

    async function selectedCarouselAdImageChanged(event) {
        event.stopPropagation();
        event.preventDefault();
        const image = event.target.files[0];
        try {

            const imageObejct = generateImageObject(URL.createObjectURL(image), image.name, true, image)
            setcarouselAdImage(imageObejct)
        } catch (error) {

        }
    }

    useEffect(() => {
        if (!isDeleteOpen)
            setDeleteRequest({})
    }, [isDeleteOpen])

    const toggleDelete = (deleteRequest) => {
        setIsDeleteOpen(!isDeleteOpen)
        setDeleteRequest(deleteRequest)
    }

    const handleDelete = async () => {
        try {

            if (deleteRequest && deleteRequest.hasOwnProperty("title")) {
                await executeDeleteCampaign()
                toggleDelete(null)
                await reload()
                showInfo("Campaign wurde erfolgreich entfernt")
                return
            }

            if (deleteRequest) {
                await executeDeleteAd(deleteRequest)
                toggleDelete(null)
                await reload()
                showInfo("Anzeige wurde erfolgreich gelöscht")
            }
        } catch (err) {
            showInfo("Etwas ist schief gegangnen \n " + err)
            console.log(err)
        }

    }

    const handleDeleteCampaign = () => {
        toggleDelete(campaign)
    }

    const displayClicks = () => {
        const bannerClicks = bannerAd.meta && bannerAd.meta.clicks ? bannerAd.meta.clicks : 0;
        const carouselClicks = carouselAd.meta && carouselAd.meta.clicks ? carouselAd.meta.clicks : 0;
        return bannerClicks + carouselClicks;
    }

    const displayViews = () => {
        const bannerViews = bannerAd.meta && bannerAd.meta.views ? bannerAd.meta.views : 0;
        const carouselViews = carouselAd.meta && carouselAd.meta.views ? carouselAd.meta.views : 0;
        return bannerViews + carouselViews;
    }

    const displayDelivered = () => {
        const bannerDelivered = bannerAd.meta && bannerAd.meta.deliverdToUser ? bannerAd.meta.deliverdToUser : 0;
        const carouselDelivered = carouselAd.meta && carouselAd.meta.deliverdToUser ? carouselAd.meta.deliverdToUser : 0;
        return bannerDelivered + carouselDelivered;
    }
    const toggleInfo = () => {
        setIsInfoOpen(!isInfoOpen)
    }

    const showInfo = (info) => {
        setInfo(info)
        toggleInfo()
    }

    return (
        <div >

            <Modal isOpen={isDeleteOpen} toggle={toggleDelete}>
                <ModalHeader toggle={toggleDelete}>Wirklich Löschen?</ModalHeader>
                <ModalBody>Wollen sie diese Anzeige wirklich löschen?</ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleDelete}>
                        OK
                    </Button>
                    <Button color="secondary" onClick={() => { setIsDeleteOpen(false) }}>
                        Abbrechen
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={isInfoOpen} toggle={toggleInfo}>
                <ModalHeader toggle={toggleInfo}>Info</ModalHeader>
                <ModalBody>{info}</ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggleInfo}>
                        OK
                    </Button>
                </ModalFooter>
            </Modal>
            <div className='mainContainer'>

                <div className='container'>
                    <div className='mainColumn'>
                        <div style={{ marginBottom: "60px" }}><h2><b>{campaign.title} </b>Campaign</h2></div>
                        <div className='expiresContainer'>
                            {/* <div >Expires : {campaign.expires} </div><FontAwesomeIcon style={{marginLeft: "20px"}} size='lg' icon={faCalendarDays}></FontAwesomeIcon> */}
                            <div >
                                <Label style={{ marginRight: "20px" }}>Campaign expires:</Label>
                                <DatePicker
                                    selected={new Date(campaign.expires)}
                                    dateFormat="dd.MM.yyyy"
                                />

                            </div>
                        </div>

                        <div className='twoRowContainer'>
                            <div className='numberComponent'>
                                <Label>Range(m)</Label>
                                <input className='defaultInput' type='number' value={range}
                                    onChange={(event) => { setRange(event.target.value) }} />
                            </div>
                            <div className='numberComponent'>
                                <Label>Frequency</Label>
                                <Input className='defaultInput' disabled={false} value={frequency}
                                    onChange={(event) => { setfrequency(event.target.value) }} />
                            </div>
                        </div>
                        <Label>Target</Label>
                        <Input className='defaultInput' type='url' value={target} onChange={(event) => { setTarget(event.target.value) }} />
                        <div className="targetTypeContainer">
                            <Label>TargetType</Label><br />
                            {renderTargetTypes()}
                        </div>
                        <div style={{ marginTop: "50px" }}>
                            <h3>Banner</h3>
                            <img src={bannerAdImage.url} width="100%" style={{}} />
                            <input type='file' id='file' onChange={selectedBannerAdImageChanged} ref={AdBannerInputFile} style={{ display: 'none' }} />
                            <Button className='defaultButton' onClick={() => { AdBannerInputFile.current.click() }}>
                                {Object.keys(bannerAd).length !== 0 ? "Banner ändern" : "Banner hinzufügen"}
                            </Button>
                            {Object.keys(bannerAd).length !== 0 &&
                                <Button className='deleteButton' onClick={() => { toggleDelete(bannerAd) }}
                                    style={{ backgroundColor: "red" }}>
                                    Banner entfernen
                                </Button>
                            }
                        </div>

                        <h3 style={{ marginTop: "40px" }}>Statistics</h3>
                        <div className='statisticsContainer'>
                            <Label>Clicks</Label>
                            <Button disabled={true} className='displayButton' style={{ marginLeft: "10px" }}>{bannerAd.meta ? bannerAd.meta.clicks : 0}</Button>

                            <Label>Views</Label>
                            <Button disabled={true} className='displayButton' style={{ marginLeft: "10px" }}>{bannerAd.meta ? bannerAd.meta.views : 0}</Button>

                            <Label>delivered</Label>
                            <Button disabled={true} className='displayButton' style={{ marginLeft: "10px" }}>{bannerAd.meta ? bannerAd.meta.deliverdToUser : 0}</Button>
                        </div>

                        <h3 style={{ marginTop: "40px" }}>Accumulated</h3>
                        <div className='statisticsContainer'>
                            <Label>Clicks</Label>
                            <Button disabled={true} className='displayButton' style={{ marginLeft: "10px" }}>{displayClicks()}</Button>

                            <Label>Views</Label>
                            <Button disabled={true} className='displayButton' style={{ marginLeft: "10px" }}>{displayViews()}</Button>

                            <Label>delivered</Label>
                            <Button disabled={true} className='displayButton' style={{ marginLeft: "10px" }}>{displayDelivered()}</Button>
                        </div>


                    </div>
                    <div className='mainColumn'>
                        <div className='advertisementLocation'>
                            <h3>Advertisment Location</h3>
                            {location.lat && location.lng &&
                                <CustomeMap 
                                    Latitude={location.lat}
                                    Longitude={location.lng}
                                    onChange={onLocationChanged} />
                            }

                            <div style={{ marginTop: "70px" }}>
                                <h3>Carousel</h3>
                                <img src={carouselAdImage.url} width="55%" style={{}} />

                                <input type='file' id='file' onChange={selectedCarouselAdImageChanged} ref={AdCarouselInputFile} style={{ display: 'none' }} />
                                <Button className='defaultButton' onClick={() => { AdCarouselInputFile.current.click() }}>
                                    {Object.keys(carouselAd).length !== 0 ? "Carousel ändern" : "Carousel hinzufügen"}
                                </Button>
                                {Object.keys(carouselAd).length !== 0 &&
                                    <Button className='deleteButton' onClick={() => { toggleDelete(carouselAd) }}
                                        style={{ backgroundColor: "red" }}>
                                        Carousel entfernen
                                    </Button>
                                }

                            </div>
                            <h3 style={{ marginTop: "40px" }}>Statistics</h3>
                            <div className='statisticsContainer'>
                                <Label>Clicks</Label>
                                <Button disabled={true} className='displayButton' style={{ marginLeft: "10px" }}>
                                    {carouselAd.meta ? carouselAd.meta.clicks : 0}</Button>

                                <Label>Views</Label>
                                <Button disabled={true} className='displayButton' style={{ marginLeft: "10px" }}>
                                    {carouselAd.meta ? carouselAd.meta.views : 0}</Button>

                                <Label>delivered</Label>
                                <Button disabled={true} className='displayButton' style={{ marginLeft: "10px" }}>
                                    {carouselAd.meta ? carouselAd.meta.deliverdToUser : 0}</Button>
                            </div>
                        </div>
                    </div>
                </div >
                <hr />
                <div className='buttonContainer'>
                    <Button className='actionButton' onClick={handleDeleteCampaign}>Löschen</Button>
                    <Button className='actionButton' onClick={handleSaveAd}>Speichern</Button>
                </div>
            </div>

        </div>
    );
};

export default AdView;