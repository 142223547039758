import React, { useEffect, useRef, useState } from "react";
import { Button, Accordion, AccordionItem, AccordionHeader, AccordionBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter, Label, ButtonGroup, Alert, } from 'reactstrap';
import logo from "../assets/logo.png"
import './Styles/Landing.css'
import flagIcon from "../assets/flag.png"
import heartIcon from "../assets/heart.png"
import { isUserAuthorized, isUserContentManager } from "../userAuth";
import { useAuth0 } from "@auth0/auth0-react";
import Location from "./LocationComponents/Location";
import ReactPaginate from "react-paginate";
import copy from "../assets/copy.png"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faPlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from 'react-router-dom';
import axios from "axios";
import ReactStars from 'react-rating-stars-component';
import CustomeMap from "./AdsComponents/CustomeMap";

const Landing = () => {

  let baseOffset = 0;
  let limit = 40;

  const filterEnum = {
    reported: "Gemeldet",
    user: "Meine Beiträge",
    social: "Social Media",
    none: "Filter Entfernen",
    mostVisited: "🔍 Meist besucht",
    mostClicked: "🖱️ Meist geklickt",
    mostLiked: "❤️ Meist geliked"
  }

  const getFilterKeyByValue = (value) => {
    return Object.entries(filterEnum).find(([key, val]) => val === value)?.[0];
  }

  const baseUrl = process.env.REACT_APP_SERVER
  const [locations, setLocations] = useState([]);
  const [locationsOriginally, setLocationsOriginally] = useState([]);
  const [filter, setFilter] = useState(filterEnum.none)
  const [offset, setOffset] = useState(baseOffset)
  const [locationsComplete, setLocationsComplete] = useState(true)
  const [idDeleteList, setIdDeleteList] = useState([])
  const [pageCount, setPageCount] = useState(0);  // Gesamtanzahl der Seiten
  const [locationsCount, setLocationsCount] = useState(0)
  const [activeId, setActiveId] = useState(0)
  const activePage = useRef(0);
  const [activeLocation, setActiveLocation] = useState()
  const [accessToken, setAccessToken] = useState("");
  const [displayedLocations, setDisplayedLocations] = useState([])
  const [isDropDownFilterOpen, setIsDropDownFilterOpen] = useState(false)
  const [query, setQuery] = useState("");
  const [filterText, setFilterText] = useState("Filter")
  const history = useHistory();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)
  const [location, setLocation] = useState({ lat: null, lng: null })
  const [selectedCategory, setSelectedCategory] = useState(null)
  const onLocationChanged = (lat, lng) => {
    setLocation({ lat: lat, lng: lng })
  }

  const [range, setRange] = useState(20)
  const [showRegion, setShwoRegion] = useState(false)



  //#region  Auth0

  const {
    user,
    getAccessTokenSilently,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });


  function logoutUser() {
    alert("Sie sind nicht berechtigt diese Seite zu benutzen. Bitte wennden sie sich an einen Adminestrator oder schreiben sie eine E-Mail an tobias.franz@posteo.de um Zugriff zu beantragen.")
    logoutWithRedirect();
  }

  //#endregion

  //#region Use Effects
  useEffect(() => {
    if (isAuthenticated) {
      const getToken = async () => {
        let accessToken = ""
        accessToken = await getAccessTokenSilently();
        setAccessToken(accessToken);
      }
      try {
        getToken();
      }
      catch (e) {
        console.log(e)
      }
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (accessToken)
      searchLocations()
  }, [location, range])

  useEffect(() => {
    if (accessToken != "") {
      setLocationsComplete(true)
    }
  }, [accessToken])

  //#endregion

  //#region Server Calls
  async function getAllLocations() {
    const categoryParam = (selectedCategory != undefined && selectedCategory != null) ? "&" + "category=" + selectedCategory.key : ""

    const url = baseUrl + `/admin/location?offset=${offset}&limit=${limit}${categoryParam}`
    console.log(url)
    const result = await fetch(url, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': baseUrl,
        'Authorization': 'Bearer ' + accessToken,
      },
    });
    if (result.status != 200)
      throw "Not Successfull Exception Status: " + result.status;

    const locationObject = await result.json();
    setLocations(locationObject.locations)
    setLocationsCount(locationObject.count)
    setPageCount(Math.ceil(locationObject.count / limit))

  }



  function toggleLocations(id) {
    activeLocation === id ? setActiveLocation(undefined) : setActiveLocation(id)
  }

  async function excuteFilter(filter) {

    const categoryParam = (selectedCategory != undefined && selectedCategory != null) ? "&" + "category=" + selectedCategory.key : ""

    let url = baseUrl + `/admin/location?offset=${offset}&limit=${limit}&filter=${filter}`
    url = url + categoryParam
    //const url = baseUrl + "/admin/location" + "?offset=" + offset + "&" + "limit=" + limit + `&filter=${filter}`

    const result = await fetch(url, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': baseUrl,
        'Authorization': 'Bearer ' + accessToken,
      },
    });
    if (result.status != 200)
      throw "Not Successfull Exception Status: " + result.status;

    const resultJson = await result.json();
    const filteredData = resultJson.locations;
    const locationCount = resultJson.count;
    setLocationsCount(locationCount)
    setLocations(filteredData)
    setPageCount(Math.ceil(locationCount / limit))
  }


  useEffect(() => {
    if (accessToken)
      searchLocations()
  }, [filter, query, selectedCategory, accessToken, offset, accessToken])

  async function searchLocations() {
    let url = ""
    const categoryParam = (selectedCategory != undefined && selectedCategory != null) ? "&" + "category=" + selectedCategory.key : ""
    url = baseUrl + `/admin/location${query ? "/" + query : ""}?offset=${offset}&limit=${limit}${getFilterKeyByValue(filter) != "none" ? "&filter=" + getFilterKeyByValue(filter) : ""}${categoryParam}${location.lat ? "&lat=" + location.lat : ""}${location.lng ? "&lng=" + location.lng : ""}${range ? "&range=" + range : ""}`
    console.log(categoryParam)
    // ToDo: No string comparation
    // if (filter != filterEnum.none) {
    //   url = url + categoryParam
    // }
    // else {
    //   //url = baseUrl + `/admin/location/${query}?offset=${offset}&limit=${limit}`
    //   url = url + categoryParam
    // }

    console.log(url)
    console.log(accessToken)
    const result = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`
      },
    });
    if (result.status != 200)
      throw "Not Successfull Exception Status: " + result.status;

    const locationObject = await result.json();
    setLocations(locationObject.locations)
    setLocationsCount(locationObject.count)
    setPageCount(Math.ceil(locationObject.count / limit))
  }
  const reload = () => {
    filterLocation(filter)
  }

  const removeFilter = () => {
    setSelectedCategory(null)
    setFilterText("Filter")
  }

  async function filterLocation(filter) {
    setFilter(filter)


    if (filter === filterEnum.none) {
      removeFilter()
    }
    else
      setFilterText(filter)
  }

  function isValidObjectId(id) {
    // Prüfen auf Länge und Hexadezimalität
    if (typeof id === 'string' && id.length === 24 && /^[a-fA-F0-9]{24}$/.test(id)) {
      return true;
    }
    return false;
  }

  async function getLocationByID(id) {
    if (!isValidObjectId(id))
      return false

    let url = baseUrl + "/location/" + id;

    const result = await fetch(url, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': baseUrl,
        'Authorization': 'Bearer ' + accessToken,
      },
    });
    if (result.status === 404)
      return false
    if (result.status != 200)
      throw "Not Successfull Exception Status: " + result.status;

    const locationObject = await result.json();
    //check for empty Object
    if (Object.keys(locationObject).length === 0)
      return false
    setLocations([locationObject])
    setLocationsCount(1)
    setPageCount(1)
    return true

  }

  async function handleSearch(query) {
    // if (query.trim() === "") {
    //   await getAllLocations();
    //   setQuery("")
    //   return
    // }
    // if (await getLocationByID(query))
    //   return


    setQuery(query)
    //    await searchLocations(query, filter)

    // let filteredData = []
    // locationsOriginally.forEach((elem, index) => {
    //   if (elem.title.includes(query))
    //     filteredData.push(elem)
    //   else if (elem.description.includes(query))
    //     filteredData.push(elem)

    // })

  }

  async function deleteLocations() {
    for (let item of idDeleteList) {
      await executeDeleteLocation(item.id)
    }

    reload()
    toggelDelete()
    uncheckAll()
  }

  async function executeDeleteLocation(id) {
    const url = baseUrl + '/admin/location/' + id

    await axios({
      method: 'delete',
      url: url,
      headers: {
        'Accept': 'application/json',
        "Content-Type": "application/json; charset=utf-8",
        'Access-Control-Allow-Origin': baseUrl,
        'Authorization': 'Bearer ' + accessToken,
      }

    }).then((res) => {
      if (res.status === 200 || res.status === 201) {
        console.log("delete location successfully")
      }
      else {
        console.log("Could not delete location {} " + res.status + " " + res.error)
      }
    })
  }
  //#endregion

  //#region render Helper

  const handlePageClick = (event) => {
    const i = event.selected;
    console.log("I= " + i)
    const offset = i * limit
    setOffset(offset)
    setActiveId(i)
    activePage.current = i;
  };


  const reanderAccordions = () => {
    if (locationsComplete) {
      return (
        <div>
          <Accordion flush open={activeLocation} toggle={toggleLocations}>
            {renderLocations(locations)}
          </Accordion>
        </div>)
    }
  }
  //#region Categorie
  const [categories, setCateogories] = useState([
    { key: "ACTIVITY", value: "Aktivität" },
    { key: "HEALTH", value: "Gesundheit" },
    { key: "SHOPPING", value: "Einkauf" },
    { key: "SUPPORT_CONSULTING", value: "Unterstützung und Beratung" },
    { key: "PLAYGROUND", value: "Spielplatz" },
    { key: "HIKING", value: "Wandern" },
    { key: "HOTEL", value: "Unterkunft" },
    { key: "EDU", value: "Bildung" },
    { key: "AQUA_FUN", value: "Wasserspaß" },
    { key: "FOOD", value: "Restaurants & Café" },
  ])



  function renderCategorieButtons() {
    const items = [];
    for (const [index, item] of categories.entries()) {
      items.push(<Button key={index} onClick={() => onCategorieButtonClick(item)} active={selectedCategory === item} >{item.value}</Button>)
    }
    return items;
  }

  // useEffect(() => {
  //   getAllLocations()
  // }, [selectedCategory])


  function onCategorieButtonClick(rSelected) {
    if (rSelected === selectedCategory)
      setSelectedCategory(null)
    else
      setSelectedCategory(rSelected)

  }
  //#endregion

  const clickCopyObjectID = (event, id) => {
    navigator.clipboard.writeText(id)
    toast.info("Objekt ID " + id + " wurde in die Zwischenablage kopiert", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000 })
    event.stopPropagation()
  }
  const OnChangeLocationCheckbox = (event, id) => {
    if (event.target.checked)
      setIdDeleteList([...idDeleteList, { "id": id, "target": event.target }])
    else
      if (idDeleteList.find(item => item.id === id))
        setIdDeleteList(idDeleteList.filter(item => item.id != id))

    console.log(idDeleteList)
    event.stopPropagation()
  }
  const uncheckAll = () => {
    for (const item of idDeleteList) {
      item.target.checked = false;
    }
    setIdDeleteList([])
  }
  const toggelDelete = () => {
    setIsDeleteOpen(!isDeleteOpen)
  }

  //render Locations with offset
  function renderLocations(locations) {
    return locations.map((location, index) => {
      const flag = location.flags.length === 0 ? false : true;
      let id = location._id;
      let likeCount = location.likes.length;
      let flagCount = location.flags.length;
      return (
        <AccordionItem key={id}>
          <AccordionHeader targetId={id}>
            <div className="listHeader">
              <div className="listHeaderItem">
                <input key={id} type="checkbox" style={{ marginRight: "20px", width: "15px", height: "15px" }}
                  onClick={(event) => { event.stopPropagation() }}
                  onChange={(event) => { OnChangeLocationCheckbox(event, location._id) }}
                />
                {location.title}
              </div>
              <div className="listHeaderItemID">
                {"ID: " + location._id}
                <img onClick={(event) => {
                  clickCopyObjectID(event, id)
                }} src={copy} width="25" height="25" style={{ marginLeft: "10px", cursor: "pointer" }}></img>

              </div>
              <div style={{ width: "600px", display: "flex", flexDirection: "row" }}>
                <ReactStars
                  count={5}
                  value={location.rating ? location.rating.average : ""}
                  edit={false}
                  size={24}
                  isHalf={true}
                  activeColor="#00A6B2"
                />
                <Label style={{ marginTop: "5px", marginLeft: "5px" }}>
                  {location.rating ? location.rating.average.toFixed(1) + "/5" : ""}
                </Label>
                <Label style={{ marginTop: "5px", marginLeft: "5px" }}>
                  {location.rating ? " (" + location.rating.count + ")" : ""}
                </Label>

              </div>
              <div className="listHeaderItemFlag">
                {flag &&

                  <div className="headerImageContainer">
                    <img src={flagIcon} width="25px"></img>
                    <div className="centerdFlagText">{flagCount}</div>
                  </div>
                }
                <div className="headerImageContainer">
                  <img src={heartIcon} width="40px"></img>
                  <div className="centerdHeartText">{likeCount}</div>
                </div>
              </div>
            </div>
          </AccordionHeader>
          <AccordionBody accordionId={id} className="accordion_body"  >
            <div>
              {activeLocation === id &&
                <Location key={id} edit={true} {...location} reload={() => { reload() }}></Location>
              }
            </div>
          </AccordionBody>
        </AccordionItem >
      )
    })
  }

  //#endregion

  return (
    <div>

      <ToastContainer />
      <Modal isOpen={isDeleteOpen} toggle={toggelDelete} >
        <ModalHeader toggle={toggelDelete}>Wirklich Löschen?</ModalHeader>
        <ModalBody>Sind sie wirklich sicher, dass sie diese Location löschen wollen? Diese Aktion ist nicht mehr rückgängig zumachen</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={deleteLocations}>Löschen!</Button>
          <Button color="primary" onClick={toggelDelete}>Cancel</Button>
        </ModalFooter>
      </Modal>
      {!isAuthenticated && (
        <div className="text-center my-5 bg-test">
          <img className="mb-3 app-logo" src={logo} alt="React logo" width="400" />

          <h2 className="mb-4" style={{ color: "#00A6B2" }}>Administration Portal</h2>

          <Button
            id="qsLoginBtn"
            color="primary"
            className="btn-margin"
            onClick={() => loginWithRedirect()}
          >
            Login
          </Button>
        </div>
      )
      }
      {isAuthenticated && !isUserAuthorized(user) && (
        <div>
          {logoutUser()}
        </div>
      )
      }
      {isAuthenticated && isUserAuthorized(user) && (
        <div>
          <h1> {locationsCount} Locations - Seite {activeId + 1}</h1>
          <hr></hr>
          <br></br>
          <div>
            <div className="full_column">
              <div className="container_row" style={{ paddingRight: 30 }}>
                <input id="search" placeholder="Suchen" className="defaultInput" onKeyDown={(e) => e.key === 'Enter' && handleSearch(e.target.value)}></input>
                {!isUserContentManager(user) && (
                  <Dropdown isOpen={isDropDownFilterOpen} toggle={() => { setIsDropDownFilterOpen(!isDropDownFilterOpen) }} >
                    <DropdownToggle caret className="filterButton" style={{ width: "200px" }}>
                      {filterText}
                    </DropdownToggle>
                    <DropdownMenu style={{ maxHeight: "400px", overflow: "scroll" }}>
                      <DropdownItem onClick={() => filterLocation(filterEnum.reported)}>🚨 Gemeldet</DropdownItem>
                      <hr />
                      <DropdownItem onClick={() => { setShwoRegion(!showRegion) }}>🌍 Region</DropdownItem>
                      <hr />
                      <DropdownItem onClick={() => filterLocation(filterEnum.mostVisited)}>🔍 Meist besucht</DropdownItem>
                      <DropdownItem onClick={() => filterLocation(filterEnum.mostClicked)}>🖱️ Meist geklickt</DropdownItem>
                      <DropdownItem onClick={() => filterLocation(filterEnum.mostLiked)}>❤️ Meist geliked</DropdownItem>
                      <hr />
                      <DropdownItem onClick={() => filterLocation(filterEnum.user)}>Meine Beiträge</DropdownItem>
                      <DropdownItem onClick={() => filterLocation(filterEnum.social)}>Social Media</DropdownItem>
                      <hr />
                      <DropdownItem onClick={() => filterLocation(filterEnum.none)}>Filter Entfernen</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                )}
              </div>
              <ButtonGroup>
                <div id="categorieChooser" className={"Search_Container_Buttonbar"}>
                  {renderCategorieButtons()}
                </div>
              </ButtonGroup>
              {showRegion &&
                <div>
                  <CustomeMap
                    Latitude={location.lat}
                    Longitude={location.lng}
                    onChange={onLocationChanged}
                    showMap={false} />
                  <div>
                    <input type="number" value={range} className="defaultInput" style={{ width: "10%" }} onChange={(e) => { setRange(e.target.value) }} />
                    <span class="unit">km</span>
                  </div>
                </div>
              }
            </div>
          </div>

          <div >
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              previousLabel="< prvious"
              onPageChange={handlePageClick}
              pageRangeDisplayed={25}
              pageCount={pageCount}
              containerClassName="pagination"
              renderOnZeroPageCount={null}
              activeClassName="active" />
            < div className="action_menu" >
              <Button onClick={() => { history.push("/new") }} className="action_button">
                <FontAwesomeIcon icon={faPlus}
                  style={{ width: "20px", height: "20px", marginRight: "10px" }} />
                <span style={{ fontSize: "20px", fontWeight: "600", marginTop: "-5px" }}>Neu</span>
              </Button>
              <div style={{ height: "50px" }} >
                {idDeleteList.length > 0 ? (
                  <div className="container_row" style={{ marginLeft: "20px" }}>
                    <Button className="action_button" style={{ marginRight: "5px" }} onClick={() => { uncheckAll() }} >
                      <FontAwesomeIcon icon={faSquare}
                        style={{ width: "20px", height: "20px", fontWeight: "200", marginRight: "5px" }} />
                      <span style={{ fontSize: "20px", fontWeight: "600", marginTop: "-5px" }}>Abwählen</span>
                    </Button>

                    <Button className="action_button" onClick={() => { toggelDelete() }} >
                      <FontAwesomeIcon icon={faTrashCan} style={{ width: "20px", height: "20px" }} />
                      <span style={{ fontSize: "20px", fontWeight: "600", marginTop: "-5px" }}>Löschen</span>
                    </Button>
                  </div>
                ) :
                  <div style={{ width: "36px", height: "36px" }}>
                  </div>
                }
              </div>
            </div>
            {reanderAccordions(displayedLocations)}
          </div>
        </div>
      )
      }
    </div >
  )
};

export default Landing;
